import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  Paper,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";

interface ImageUploadProps {
  onFileUploaded: (file: File | null) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onFileUploaded }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const fileExtension = file.type.split(".").pop()?.toLowerCase() || "";

        if (SUPPORTED_FORMATS.includes(fileExtension)) {
          setUploadedFile(file);
          onFileUploaded(file);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFileUploaded]
  );

  const onDeleteFile = () => {
    setUploadedFile(null);
    onFileUploaded(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <Paper elevation={3} style={{ padding: "16px", textAlign: "center" }}>
      <div {...getRootProps()} style={dropzoneStyles(isDragActive)}>
        <input {...getInputProps()} />
        <CloudUploadIcon style={{ fontSize: 80, color: "#2196F3" }} />
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          {isDragActive
            ? "Drop a file here"
            : "Drag and drop a file here, or click to select a file"}
        </Typography>
      </div>
      {uploadedFile && (
        <ListItem sx={{ listStyle: "none" }}>
          <ListItemText
            sx={{ listStyle: "none" }}
            primary={uploadedFile.name}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={onDeleteFile}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Paper>
  );
};

const dropzoneStyles = (isDragActive: boolean): React.CSSProperties => ({
  border: `2px dashed ${isDragActive ? "#2196F3" : "#888888"}`,
  borderRadius: "4px",
  padding: "32px",
  cursor: "pointer",
});

export default ImageUpload;
