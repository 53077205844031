import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";

interface ImageProps {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
}

const Img: React.FC<ImageProps> = ({ src, alt, width, height }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => setIsLoading(false);
    image.onerror = () => setIsLoading(false);
  }, [src]);

  return (
    <div>
      {isLoading ? (
        <CircularProgress aria-labelledby={src} />
      ) : (
        <img
          src={src}
          alt={alt}
          loading="lazy"
          style={{ maxWidth: "100%", height, width }}
        />
      )}
    </div>
  );
};

export default Img;
