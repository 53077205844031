import { Avatar, TableCell } from "@mui/material";
import React, { FC, useEffect, useState } from "react";

interface CourseImageProps {
  name: string;
  imageName: string;
}

const CourseImage: FC<CourseImageProps> = ({ imageName, name }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const imageUrl = `https://nextgen.am/api/v1/file/download/${imageName}`;

    fetch(imageUrl, {
      headers: {
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Get the response as a binary blob
      })
      .then((blob) => {
        // Create a URL for the blob data
        const objectURL = URL.createObjectURL(blob);
        setImageSrc(objectURL);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  }, [imageName]); // Empty

  return (
    <TableCell
      component="th"
      sx={{
        border: "none",
      }}
      scope="row"
      id="tableCell"
    >
      <Avatar
        sx={{
          transition: "transform 0.3s", // Add a CSS transition
          "&:hover": {
            position: "absolute",
            transform: "scale(4)",
            zIndex: 9999,
          },
          "& img": {
            objectFit: "cover",
          },
        }}
        alt={name}
        src={imageSrc}
      />
    </TableCell>
  );
};

export default CourseImage;
