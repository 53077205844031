import { Box } from "@mui/material";
import { FC } from "react";
import FullSizeImage from "../full-size-image/FullSizeImage";

interface CommunityImageProps {
  imageName: string;
}

const CommunityImage: FC<CommunityImageProps> = ({ imageName }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <FullSizeImage imageName={imageName} />
    </Box>
  );
};

export default CommunityImage;
