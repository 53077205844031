import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  ClickAwayListener,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { FC, useState } from "react";
import logo from "../../icons/NG-logo--.png";
import NavLink from "../nav-link/NavLink";
import Navbar from "../navbar/Navbar";
import HeaderScroll from "../header-scroll/HeaderScroll";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useLocation } from "react-router-dom";
import HeaderScrollButton from "../header-scroll-button/HeaderScrollButton";
import LanguageSelect from "../language-select/LanguageSelect";

const drawerWidth = 240;

interface HeaderContainerProps {
  window?: () => Window;
}

export const HeaderContainer: FC<HeaderContainerProps> = ({ window }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const isLogin = useSelector((state: RootState) => state.login.login);

  const drawer = (
    <Box
      onClick={() => setMobileOpen(true)}
      sx={{ textAlign: "center", height: "100%" }}
    >
      <ClickAwayListener onClickAway={() => setMobileOpen(false)}>
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" sx={{ my: 2 }}>
              NextGen
            </Typography>
            <LanguageSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "90%",
            }}
          >
            <Box>
              <List>
                <Navbar />
              </List>
            </Box>
            <Box>
              <Divider />
              <Box
                sx={{
                  textAlign: "left",
                  padding: "30px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <HeaderScrollButton
                  link="/"
                  pageId={0}
                  scrollTop={0}
                  value={t("educationalProgramOfTheAcademy")}
                  showActive={true}
                />
                <HeaderScrollButton
                  link="/"
                  pageId={0}
                  scrollTop={2600}
                  value={t("feedBack")}
                  showActive={true}
                />

                <HeaderScrollButton
                  link="/"
                  pageId={0}
                  scrollTop={3420}
                  value={t("ourTeam")}
                  showActive={true}
                />

                <HeaderScrollButton
                  link="/"
                  pageId={0}
                  scrollTop={5000}
                  value={t("contactUs")}
                  showActive={true}
                />
              </Box>
            </Box>
          </Box>
        </>
      </ClickAwayListener>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display:
          isLogin || pathname === "/admin" || pathname === "/admin/"
            ? "none"
            : "flex",
      }}
    >
      <CssBaseline />

      <AppBar
        component="nav"
        sx={{ backgroundColor: "#dee8f2f7", paddingRight: "50px" }}
      >
        <Toolbar
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            justifyContent: "space-between",
          }}
        >
          <Box
            component={"span"}
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <HeaderScrollButton
              link="/"
              pageId={0}
              scrollTop={0}
              value={t("educationalProgramOfTheAcademy")}
              showActive={true}
            />
            <HeaderScrollButton
              link="/"
              pageId={0}
              scrollTop={1300}
              value={t("feedBack")}
              showActive={true}
            />

            <HeaderScrollButton
              link="/"
              pageId={0}
              scrollTop={2100}
              value={t("ourTeam")}
              showActive={true}
            />

            <HeaderScrollButton
              link="/"
              pageId={0}
              scrollTop={5000}
              value={t("contactUs")}
              showActive={true}
            />
          </Box>
          <Box component={"span"}>
            <LanguageSelect />
          </Box>
        </Toolbar>

        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "none" } }}
          >
            MUI
          </Typography> */}
          <Box
            sx={{
              width: "200px",
              height: "110px",
            }}
          >
            <img
              alt="img"
              loading="lazy"
              style={{ width: "100%", height: "100%" }}
              src={logo}
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              gap: "1rem",
            }}
          >
            {/* <NavLink to="/">{t("homepage")}</NavLink> */}
            {/* <HeaderScroll
              link="/"
              pageId={11}
              options={[
                {
                  label: t("educationalProgramOfTheAcademy"),
                  scrollTop: 0,
                  pageId: 1,
                },
                { label: t("feedBack"), scrollTop: 1550, pageId: 2 },
                { label: t("ourTeam"), scrollTop: 100, pageId: 3 },
                { label: t("contactUs"), scrollTop: 100, pageId: 4 },
              ]}
              title={t("nextGen")}
            /> */}
            <HeaderScroll
              pageId={12}
              link="/about"
              options={[
                {
                  label: t("expectedEndResult"),
                  scrollTop: 0,
                  pageId: 12,
                },
                { label: t("learningFormat"), scrollTop: 650, pageId: 12 },
                { label: t("speakers"), scrollTop: 1000, pageId: 12 },
                { label: t("rules"), scrollTop: 1800, pageId: 12 },
              ]}
              title={t("aboutAcademy")}
            />

            <NavLink pageId={9} to="/community">
              {t("NextGenCommunity")}
            </NavLink>
            <NavLink pageId={10} to="/talks">
              {t("NextGenTalks")}
            </NavLink>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
export default HeaderContainer;
