import React, { FC, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
} from "@mui/material";
import { useChangeStatsMutation } from "../../api";
export interface StatsData {
  id: string | number;
  academy: number;
  students: number;
  courses: number;
}

interface StaffProps {
  data: StatsData;
}

const Staff: FC<StaffProps> = ({ data }) => {
  const [state, setState] = useState<StatsData>({
    academy: 0,
    courses: 0,
    students: 0,
    id: 0,
  });

  const [changeData] = useChangeStatsMutation();

  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <Box display="flex" justifyContent="space-between">
      <TextField
        type="number"
        value={state.academy}
        onChange={(e) => {
          setState((state) => {
            return {
              academy: +e.target.value,
              courses: state.courses,
              id: state.id,
              students: state.students,
            };
          });
        }}
        variant="outlined"
        label="Academy"
        placeholder="academy"
      />
      <TextField
        type="number"
        value={state.students}
        onChange={(e) => {
          setState((state) => {
            return {
              academy: state.academy,
              courses: state.courses,
              id: state.id,
              students: +e.target.value,
            };
          });
        }}
        variant="outlined"
        label="Participant"
        placeholder="students"
      />
      <TextField
        type="number"
        value={state.courses}
        onChange={(e) => {
          setState((state) => {
            return {
              academy: state.academy,
              courses: +e.target.value,
              id: state.id,
              students: state.courses,
            };
          });
        }}
        variant="outlined"
        label="Community Program"
        placeholder="courses"
      />
      <Button
        variant="outlined"
        type="button"
        onClick={() => {
          changeData(state).then(() => {});
        }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default Staff;
