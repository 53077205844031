import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { FC, memo, useState } from "react";
import NavbarScrollButton from "../navbar-scroll-button/NavbarScrollButton";

type label = {
  value: string;
  label: string;
  scrollTop: number;
};

interface NavbarSelectProps {
  generalLabel: string;
  labels: label[];
}

const NavbarSelect: FC<NavbarSelectProps> = ({ generalLabel, labels }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={generalLabel} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {labels.map((label) => (
            <NavbarScrollButton
              label={label.label}
              scrollTop={label.scrollTop}
              value={label.value}
              key={label.value}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default memo(NavbarSelect);
