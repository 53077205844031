import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from "@mui/material";
import { FastField, FieldMetaProps, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useAddFeedbackMutation } from "../../api";

interface FastFiledProps {
  field: FieldMetaProps<string>;
  meta: FieldMetaProps<string>;
  form: any;
}

interface FormValues {
  name: string;
  engName: string;
  text: string;
  engText: string;
}

const AddFeedback = () => {
  const [AddFeedback] = useAddFeedbackMutation();

  const [language, setLanguage] = useState<string>("am");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: FormValues) => {
    AddFeedback({
      name: values.name,
      engName: values.engName,
      text: values.text,
      engText: values.engText,
    });
    handleClose();
  };

  const initialValues = {
    name: "",
    engName: "",
    text: "",
    engText: "",
  };
  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Add Feedback
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
        style={{ minWidth: "500px" }}
      >
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>Add Feedback</DialogTitle>
          <Box display="flex" marginRight="24px">
            <Button
              onClick={() => {
                setLanguage("am");
              }}
              variant={language === "am" ? "contained" : "outlined"}
              sx={{ height: "40px", borderRadius: "0px", marginTop: "16px" }}
            >
              Հայերեն
            </Button>
            <Button
              variant={language === "en" ? "contained" : "outlined"}
              onClick={() => {
                setLanguage("en");
              }}
              sx={{ height: "40px", borderRadius: "0px", marginTop: "16px" }}
            >
              English
            </Button>
          </Box>
        </Box>
        <DialogContent>
          <Box width="100%" height="100%" padding="8px">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("name is required"),
                text: Yup.string().required("description is required"),
                engName: Yup.string().required("name is required"),
                engText: Yup.string().required("description is required"),
              })}
              onSubmit={handleSubmit}
            >
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  display={language === "am" ? "flex" : "none"}
                  flexDirection="column"
                  gap={"10px"}
                >
                  <FastField name="name">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Name"
                          name="name"
                          id="name"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("name", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                  <FastField name="text">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Description"
                          name="text"
                          id="text"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("text", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                </Box>
                <Box
                  display={language === "en" ? "flex" : "none"}
                  flexDirection="column"
                  gap={"10px"}
                >
                  <FastField name="engName">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Name"
                          name="engName"
                          id="engName"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("engName", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                  <FastField name="engText">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Description"
                          name="engText"
                          id="engText"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("engText", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                </Box>

                <DialogActions>
                  <Button type="submit" color="primary">
                    Add
                  </Button>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Form>
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddFeedback;
