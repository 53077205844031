import React, { FC } from "react";
import { CourseResponseModel } from "../../models";
import CommunityImageList from "./CommunityImageList";
import { Box, Typography } from "@mui/material";

interface CommunityListProps {
  data: CourseResponseModel[];
}

const CommunityList: FC<CommunityListProps> = ({ data }) => {
  return (
    <Box marginY="50px" display="flex" flexDirection="column" gap="10px">
      {data.map((community) => (
        <Box
          border="1px solid rgb(110, 193, 228)"
          padding="20px"
          borderRadius="8px"
          gap="5px"
        >
          <Typography
            marginBottom="20px"
            color="rgb(110, 193, 228)"
            variant="h5"
          >
            {community.name}
          </Typography>
          <Typography marginBottom="20px">{community.text} </Typography>
          <CommunityImageList images={community.imageNames} />
        </Box>
      ))}
    </Box>
  );
};

export default CommunityList;
