import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FeedbackRequestModel, FeedbackResponseModel } from "../models";

const baseUrl = "https://nextgen.am/api/v1";

export const feedbackApi = createApi({
  reducerPath: "feedback",
  tagTypes: ["Feedback"],
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getFeedbacks: builder.query<FeedbackResponseModel[], { language: string }>({
      query: ({ language }) => ({
        url: `/feedback/${language}`,
        method: "GET",
      }),
      providesTags: ["Feedback"],
    }),
    addFeedback: builder.mutation<FeedbackResponseModel, FeedbackRequestModel>({
      query: (newFeedback) => ({
        url: "/feedback",
        method: "POST",
        body: newFeedback,
      }),

      invalidatesTags: ["Feedback"],
    }),
    deleteFeedback: builder.mutation<void, { id: string | number }>({
      query: ({ id }) => ({
        url: `/feedback/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Feedback"],
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const {
  useGetFeedbacksQuery,
  useAddFeedbackMutation,
  useDeleteFeedbackMutation,
} = feedbackApi;
