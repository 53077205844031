import { Box, Container, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Restart from "../../icons/restart-erkar.png";
import IPP from "../../icons/IPP.png";
import NavLink from "../nav-link/NavLink";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { pathname } = useLocation();
  const isLogin = useSelector((state: RootState) => state.login.login);
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        display:
          isLogin || pathname === "/admin" || pathname === "/admin/"
            ? "none"
            : "flex",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container
        sx={{
          maxWidth: "none",
        }}
      >
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box
              width="100%"
              onClick={() =>
                window.open("https://allmylinks.com/restart-foundation")
              }
              sx={{
                cursor: "pointer",
                "& img": {
                  width: "100%",
                },
              }}
            >
              <img src={Restart} loading="lazy" alt="nextGen" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" color="text.primary" gutterBottom>
              NextGen
            </Typography>
            <NavLink
              pageId={12}
              children={t("aboutAcademy")}
              to="/about"
            ></NavLink>
            <NavLink
              pageId={9}
              children={t("NextGenCommunity")}
              to="/community"
            ></NavLink>
            <NavLink
              pageId={10}
              children={t("NextGenTalks")}
              to="/talks"
            ></NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box
              width="100%"
              onClick={() => {
                window.open("http://ipp.am/");
              }}
              sx={{
                cursor: "pointer",
                "& img": {
                  width: "100%",
                },
              }}
            >
              <img src={IPP} loading="lazy" alt="nextGen" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://allmylinks.com/acad-nextgen?fbclid=IwAR2OlC33VVglg5NyBQErX8es-0pM0ZQx8ieg1Lpw-gTtIC55L3xyiAHGlNI"
                );
              }}
            >
              <Facebook />
              <Instagram />
              <YouTube />
            </Box>
            <Typography variant="body2" color="text.secondary">
              Email: acad.nextgen@gmail.com
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +374 93 533611
            </Typography>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}

            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
