export interface ScrollHandlerProps {
  scrollTop: number;
}

const ScrollHandler = ({ scrollTop }: ScrollHandlerProps) => {
  window.scrollTo({
    top: scrollTop,
    behavior: "smooth",
  });
};

export default ScrollHandler;
