import { FC } from "react";
import { Container, Grid, Typography } from "@mui/material";
import MultiImageUpload from "./MultiImageUpload";
import { useFormikContext } from "formik";

interface MultipleUploadContainerProps {}

const MultipleUploadContainer: FC<MultipleUploadContainerProps> = () => {
  const formik = useFormikContext();

  const handleFilesUploaded = (files: File[]) => {
    formik.setFieldValue("image", files);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Upload Image</Typography>
        </Grid>
        <Grid item xs={12}>
          <MultiImageUpload onFilesUploaded={handleFilesUploaded} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MultipleUploadContainer;
