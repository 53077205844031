import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useState, SyntheticEvent, useEffect } from "react";
import StudyFormatJSON from "./StudyFormat.json";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const selector = useSelector((state: RootState) => state.collapse.opened);

  const { t } = useTranslation();

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (selector) {
      setExpanded(false);
    }
  }, [selector]);

  return (
    <Box marginBottom="10px">
      {StudyFormatJSON.map((item) => (
        <Accordion
          key={item.title}
          expanded={expanded === item.title}
          onChange={handleChange(item.title)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography color="rgb(110, 193, 228)" variant="h6">
              {t(item.title)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t(item.description)}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
