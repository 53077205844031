import React from "react";
import { useGetCoursesQuery } from "../../api";
import CommunityList from "./CommunityList";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CommunityListContainer = () => {
  const { t, i18n } = useTranslation();

  const { data = [], isLoading } = useGetCoursesQuery({ lang: i18n.language });

  return (
    <div>
      <Typography color="rgb(110, 193, 228)" variant="h3">
        {t("NextGenCommunity")}
      </Typography>
      {isLoading || data.length === 0 ? (
        <CircularProgress aria-labelledby="progress" />
      ) : (
        <CommunityList data={data} />
      )}
    </div>
  );
};

export default CommunityListContainer;
