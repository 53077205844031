import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import ProfileImage from "./PeofileImage";

interface ProfileCardProps {
  img: string;
  name: string;
  text: string;
}

const ProfileCard: FC<ProfileCardProps> = ({ img, name, text }) => {
  return (
    <Box width="250px" position={"relative"} borderRadius="8px">
      <Box
        width="100%"
        height="150px"
        sx={{
          background: "linear-gradient(45deg, #ffff, #6EC1E4)",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      ></Box>
      <Box
        position="absolute"
        top="50px"
        left="50%"
        sx={{
          transform: "translate(-50%, 0px)",
          boxShadow: "0px 15px 20px rgba(0, 0, 0, 0.5)",
        }}
        width="150px"
        height="150px"
        borderRadius="50%"
      >
        <ProfileImage imageName={img} name={name} />
      </Box>
      <Box
        width="250px"
        height="400px"
        bgcolor="white"
        border="2px solid #6EC1E4"
        borderTop="none"
        sx={{
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Box paddingTop="80px">
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6" color="#6EC1E4">
              {name}
            </Typography>
          </Box>
          <Box paddingTop="20px" paddingX="20px">
            <Typography
              display="-webkit-box"
              sx={{
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "10",
                overflow: "hidden",
              }}
            >
              {text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCard;
