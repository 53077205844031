import { FC } from "react";
import AboutAcademyList from "./AboutAcademyList";
import data from "./AcademyList.json";
import { Box, Typography } from "@mui/material";
import useDeviceSizes from "../../hooks/DeviceSize";
import { useTranslation } from "react-i18next";

interface AboutAcademyListContainerProps {}

const AboutAcademyListContainer: FC<AboutAcademyListContainerProps> = () => {
  const device = useDeviceSizes();
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant={device === "mobile" || "tablet" ? "h4" : "h3"}
        color={"rgb(110, 193, 228)"}
        marginY="25px"
      >
        {t("expectedEndEducationalResults")}
      </Typography>
      <Typography variant="h5">
        {t("expectedEndEducationalResultsTitle")}
      </Typography>
      <Box display="flex" flexDirection="column" gap="30px" marginY="50px">
        {data.map((list, index) => (
          <AboutAcademyList data={list} key={index} />
        ))}
      </Box>
    </>
  );
};

export default AboutAcademyListContainer;
