import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginRequestModel, LoginResponseModel } from "../models";

const baseUrl = "https://nextgen.am/api/v1";

export const loginApi = createApi({
  reducerPath: "loginReducer",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseModel, LoginRequestModel>({
      query: (login) => ({
        url: "/login",
        method: "POST",
        body: login,
      }),
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const { useLoginMutation } = loginApi;
