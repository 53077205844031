// talksApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  TalksResponseModel,
  TalksRequestModel,
  GetTalksRequestModel,
} from "../models";

const baseUrl =
  "https://nextgen.am/api/v1";

export const talksApi = createApi({
  reducerPath: "talks",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Talk"],
  endpoints: (builder) => ({
    getTalks: builder.query<TalksResponseModel[], GetTalksRequestModel>({
      query: ({ lang }) => ({
        url: `/talks/${lang}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }),
      providesTags: ["Talk"],
    }),

    addTalks: builder.mutation<TalksResponseModel, TalksRequestModel>({
      query: (newTalk) => ({
        url: "/talks",
        method: "POST",
        body: newTalk,
      }),
      invalidatesTags: ["Talk"],
    }),

    deleteTalks: builder.mutation<TalksResponseModel, { id: string | number }>({
      query: ({ id }) => ({
        url: `/talks/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Talk"],
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const { useGetTalksQuery, useAddTalksMutation, useDeleteTalksMutation } =
  talksApi;
