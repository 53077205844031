import { useGetStaffQuery } from "../api";
import StaffContainer from "../components/staff/StaffContainer";
import { useCallback, useState } from "react";

const Staff = () => {
  const [language, setLanguage] = useState("en");

  const changeLanguage = useCallback((e: string) => {
    setLanguage(e);
  }, []);

  const { data = [] } = useGetStaffQuery({ lang: language });

  return (
    <StaffContainer
      lang={language}
      changeLanguage={changeLanguage}
      data={data}
    />
  );
};

export default Staff;
