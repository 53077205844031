import { useCallback, useState } from "react";
import { useGetCoursesQuery } from "../api";
import CourseContainer from "../components/course/CourseContainer";

const Course = () => {
  const [language, setLanguage] = useState("en");

  const changeLanguage = useCallback((e: string) => {
    setLanguage(e);
  }, []);

  const { data = [] } = useGetCoursesQuery({ lang: language });

  return (
    <CourseContainer
      lang={language}
      changeLanguage={changeLanguage}
      data={data}
    />
  );
};

export default Course;
