import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

export interface FeedbackProps {
  name: string;
  desciption: string;
}

const Feedback: FC<FeedbackProps> = ({ name, desciption }) => {
  return (
    <Box
      sx={{
        marginY: "25px",
        border: "2px solid rgb(110, 193, 228)",
        borderRadius: "8px",
        padding: "12px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginY: "15px",
        }}
      >
        {name}
      </Typography>
      <Typography>{desciption}</Typography>
    </Box>
  );
};

export default Feedback;
