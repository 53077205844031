import React, { useState, useEffect, FC } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface FullSizeImageProps {
  imageName: string;
}

const FullSizeImage: FC<FullSizeImageProps> = ({ imageName }) => {
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const imageUrl = `https://nextgen.am/api/v1/file/download/${imageName}`;

    fetch(imageUrl, {
      headers: {
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Get the response as a binary blob
      })
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  }, [imageName]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <CircularProgress aria-labelledby={imageName} />
      ) : (
        <>
          <img
            src={imageSrc}
            alt={imageName}
            loading="lazy"
            style={{ cursor: "pointer", width: "100%", height: "auto" }}
            onClick={handleOpen}
          />
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{ position: "absolute", top: "0px", right: "7px" }}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={imageSrc}
                alt={imageName}
                loading="lazy"
                style={{ width: "100%", height: "auto" }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default FullSizeImage;
