import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./LanguageSelect.module.scss";
import Select from "react-select";
import useDeviceSizes from "../../hooks/DeviceSize";

interface Option {
  value: string;
  label: string | JSX.Element;
  icon?: JSX.Element;
}

const LanguageSelect = () => {
  const device = useDeviceSizes();
  console.log(
    "🚀 ~ file: LanguageSelect.tsx:15 ~ LanguageSelect ~ device:",
    device
  );

  const options: Option[] = [
    {
      value: "am",
      label: (
        <span
          style={{
            height: "20px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <img
            loading="lazy"
            alt="am"
            src="data:image/svg+xml;utf8,%3Csvg width='21' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'%3E%3Cstop stop-color='%23FFF' offset='0%'/%3E%3Cstop stop-color='%23F0F0F0' offset='100%'/%3E%3C/linearGradient%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='b'%3E%3Cstop stop-color='%231047B9' offset='0%'/%3E%3Cstop stop-color='%2306379D' offset='100%'/%3E%3C/linearGradient%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='c'%3E%3Cstop stop-color='%23F01C31' offset='0%'/%3E%3Cstop stop-color='%23D70A1F' offset='100%'/%3E%3C/linearGradient%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='d'%3E%3Cstop stop-color='%23F5B23E' offset='0%'/%3E%3Cstop stop-color='%23F0A728' offset='100%'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='url(%23a)' d='M0 0h21v15H0z'/%3E%3Cpath fill='url(%23b)' d='M0 5h21v5H0z'/%3E%3Cpath fill='url(%23c)' d='M0 0h21v5H0z'/%3E%3Cpath fill='url(%23d)' d='M0 10h21v5H0z'/%3E%3C/g%3E%3C/svg%3E"
          />
          <p>Հայերեն</p>
        </span>
      ),
    },
    {
      value: "en",
      label: (
        <span
          style={{
            height: "20px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <img
            loading="lazy"
            alt="am"
            src="data:image/svg+xml;utf8,%3Csvg width='21' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'%3E%3Cstop stop-color='%23FFF' offset='0%'/%3E%3Cstop stop-color='%23F0F0F0' offset='100%'/%3E%3C/linearGradient%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='b'%3E%3Cstop stop-color='%23D02F44' offset='0%'/%3E%3Cstop stop-color='%23B12537' offset='100%'/%3E%3C/linearGradient%3E%3ClinearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='c'%3E%3Cstop stop-color='%2346467F' offset='0%'/%3E%3Cstop stop-color='%233C3C6D' offset='100%'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='url(%23a)' d='M0 0h21v15H0z'/%3E%3Cpath d='M0 0h21v1H0V0zm0 2h21v1H0V2zm0 2h21v1H0V4zm0 2h21v1H0V6zm0 2h21v1H0V8zm0 2h21v1H0v-1zm0 2h21v1H0v-1zm0 2h21v1H0v-1z' fill='url(%23b)'/%3E%3Cpath fill='url(%23c)' d='M0 0h9v7H0z'/%3E%3Cpath d='M1.5 2a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm-5 1a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1z' fill='url(%23a)'/%3E%3C/g%3E%3C/svg%3E"
          />
          <p>English</p>
        </span>
      ),
    },
  ];

  const [value, setValue] = useState<Option | null>(options[0]);

  const { i18n } = useTranslation();

  // const CustomOption: FC<any> = ({ innerProps, label, data }) => (
  //   <div className={styles.CustomOption} {...innerProps}>
  //     {data.label}
  //   </div>
  // );

  return (
    <Select
      styles={{
        valueContainer: (provided) => ({
          ...provided,
          justifyContent: "center",
        }),
        singleValue: (provided) => ({
          ...provided,
          span: {
            img: {
              marginRight: "10px",
            },
          },
        }),
        menuList: (provided) => ({
          ...provided,
          backgroundColor: device === "desktop" ? "#DEEBFF" : "transparent",
        }),
        input: (provided) => ({
          ...provided,
          display: "flex",
          justifyContent: "center",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: "transparent",
        }),
        container: (provided) => ({
          ...provided,
          border: "none",
          backgroundColor: device === "desktop" ? "#DEEBFF" : "transparent",
        }),
        control: (provided) => ({
          ...provided,
          border: 0,
          boxShadow: "none",
          cursor: "pointer",
          backgroundColor: "transparent",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#DEEBFF" : "transparent",
          color: "black",
        }),
        menu: (provided) => ({
          ...provided,
          justifyContent: "center",
        }),
      }}
      options={options}
      value={value}
      isSearchable={false}
      onChange={(e) => {
        i18n.changeLanguage(e?.value);
        setValue(e);
      }}
      className={styles.select}
      // components={{ Option: CustomOption }}
    />
  );
};

export default LanguageSelect;
