import { useCallback, useState } from "react";
import { useGetFeedbacksQuery } from "../api";
import FeedbackContainer from "../components/feedback/FeedbackContainer";

const Feedbacks = () => {
  const [language, setLanguage] = useState("en");

  const changeLanguage = useCallback((e: string) => {
    setLanguage(e);
  }, []);

  const { data = [] } = useGetFeedbacksQuery({ language });

  return (
    <FeedbackContainer
      lang={language}
      changeLanguage={changeLanguage}
      data={data}
    />
  );
};

export default Feedbacks;
