import { useState } from "react";
import { IconButton } from "@mui/material";
import { FC } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { useDeleteCourseMutation } from "../../api";

interface DeleteCourseProps {
  id: string | number;
}

const DeleteCourse: FC<DeleteCourseProps> = ({ id }) => {
  const [open, setOpen] = useState(false);

  const [deletePost] = useDeleteCourseMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deletePost({ id });
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <DeleteIcon color="warning" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
        style={{ minWidth: "500px" }}
      >
        <DialogTitle>Are you want to delete Course</DialogTitle>
        <DialogContent>
          <Box width="100%" height="100%" padding="8px"></Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" gap="10px">
            <Button variant="outlined" color="success" onClick={handleDelete}>
              Accept
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Decline
            </Button>
          </Box>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCourse;
