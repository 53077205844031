import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material";
import React, { FC, useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface AboutAcademyListProps {
  data: any[];
}

const AboutAcademyList: FC<AboutAcademyListProps> = ({ data }) => {
  const { t } = useTranslation();
  const selector = useSelector((state: RootState) => state.collapse.opened);

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (selector) {
      setExpanded(false);
    }
  }, [selector]);

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="rgb(110, 193, 228)" variant="h5">
          {t(data[0])}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography variant="h6" color="rgb(110, 193, 228)">
            {t("know")}
          </Typography>
          <Box component="ul">
            {Object.values(data[1]).map((value, index) => (
              <Box
                key={index}
                sx={{ listStyle: "none" }}
                component="li"
                display="flex"
                gap="10px"
              >
                <Typography> {index + 1}.</Typography>
                {/* @ts-expect-error */}
                <Typography>{t(value)}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" color="rgb(110, 193, 228)">
            {t("BeAbleTo")}
          </Typography>
          <Box component="ul">
            {Object.values(data[2]).map((value, index) => (
              <Box
                key={index}
                sx={{ listStyle: "none" }}
                component="li"
                display="flex"
                gap="10px"
              >
                <Typography> {index + 1}.</Typography>
                {/* @ts-expect-error */}
                <Typography>{t(value)}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AboutAcademyList;
