import React, { FC } from "react";
import { TalksResponseModel } from "../../models";
import TalksImageList from "./TalksImageList";
import { Box, Typography } from "@mui/material";

interface TalksListProps {
  data: TalksResponseModel[];
}

const TalksList: FC<TalksListProps> = ({ data }) => {
  return (
    <Box marginY="50px" display="flex" flexDirection="column" gap="10px">
      {data.map((talk) => (
        <Box
          border="1px solid rgb(110, 193, 228)"
          padding="20px"
          borderRadius="8px"
          gap="5px"
        >
          <Typography marginBottom="20px">{talk.text} </Typography>
          <TalksImageList images={talk.imageNames} />
        </Box>
      ))}
    </Box>
  );
};

export default TalksList;
