// courseApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CourseResponseModel, CourseRequestModel } from "../models";

const baseUrl = "https://nextgen.am/api/v1";

export const courseApi = createApi({
  reducerPath: "courses",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Course"],
  endpoints: (builder) => ({
    // Define an endpoint for fetching todos
    getCourses: builder.query<CourseResponseModel[], { lang: string }>({
      query: ({ lang }) => ({
        url: `/course/${lang}`,
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "https://nextgen.am",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      }),
      providesTags: ["Course"],
    }),

    addCourse: builder.mutation<CourseResponseModel, CourseRequestModel>({
      query: (newCourse) => ({
        url: "/course",
        method: "POST",
        body: newCourse,
      }),
      invalidatesTags: ["Course"],
    }),

    deleteCourse: builder.mutation<
      CourseResponseModel,
      { id: string | number }
    >({
      query: ({ id }) => ({
        url: `/course/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Course"],
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const {
  useGetCoursesQuery,
  useAddCourseMutation,
  useDeleteCourseMutation,
} = courseApi;
