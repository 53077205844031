import { configureStore } from "@reduxjs/toolkit";
import pageReducer from "./pageSlice";
import loginReducer from "./loginSlice";
import collapseReducer from "./collapseSlice";
import {
  speakerApi,
  courseApi,
  feedbackApi,
  staffApi,
  uploadApi,
  talksApi,
  loginApi,
  statsApi,
} from "../api";

const store = configureStore({
  reducer: {
    page: pageReducer,
    login: loginReducer,
    collapse: collapseReducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [speakerApi.reducerPath]: speakerApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [talksApi.reducerPath]: talksApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(feedbackApi.middleware)
      .concat(speakerApi.middleware)
      .concat(courseApi.middleware)
      .concat(uploadApi.middleware)
      .concat(talksApi.middleware)
      .concat(loginApi.middleware)
      .concat(staffApi.middleware)
      .concat(statsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
