import React, { FC, memo } from "react";
import ScrollHandler from "../../helpers/scrollHandler";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./HeaderScrollButton.module.scss";
import { setPage } from "../../store/pageSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setOpened } from "../../store/collapseSlice";

interface HeaderScrollButtonProps {
  scrollTop: number;
  value: string;
  link: string;
  pageId: number;
  showActive?: boolean;
}

const HeaderScrollButton: FC<HeaderScrollButtonProps> = ({
  scrollTop,
  value,
  link,
  pageId,
  showActive = false,
}) => {
  const page = useSelector((state: RootState) => state.page.page);

  const dispatch = useDispatch();

  const buttonHeanler = () => {
    ScrollHandler({ scrollTop });
    dispatch(setPage(pageId));
    dispatch(setOpened(true));
    setTimeout(() => {
      dispatch(setOpened(false));
    }, 100);
  };

  return (
    <Link
      // style={page === pageId ? { color: "#6ec1e4" } : {}}
      to={link}
      onClick={buttonHeanler}
      className={classNames(styles.button, {
        // [styles.button_active]: selected,
        [styles.button_active]: page === pageId,
        [styles.button_showActive]: showActive === true,
      })}
    >
      {value}
    </Link>
  );
};

export default memo(HeaderScrollButton);
