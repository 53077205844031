import React from "react";
import { useGetTalksQuery } from "../../api";
import TalksList from "./TalksList";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TalksListContainer = () => {
  const { t, i18n } = useTranslation();

  const { data = [], isLoading } = useGetTalksQuery({ lang: i18n.language });

  return (
    <div>
      <Typography color="rgb(110, 193, 228)" variant="h3">
        {t("NextGenTalks")}
      </Typography>
      {isLoading || data.length === 0 ? (
        <CircularProgress aria-labelledby="progress" />
      ) : (
        <TalksList data={data} />
      )}
    </div>
  );
};

export default TalksListContainer;
