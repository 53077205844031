import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  opened: boolean;
}

const initialState: CounterState = {
  opened: false,
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setOpened(state, action: PayloadAction<any>) {
      state.opened = action.payload;
    },
  },
});

export const { setOpened } = counterSlice.actions;
export default counterSlice.reducer;
