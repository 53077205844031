import React, { FC } from "react";
import FeedbackContainer from "../components/feedbacks/FeedbackContainer";
import EducationalProgramContainer from "../components/educetional-program/EducationalProgramContainer";
import TeamCardContainer from "../components/team/TeamCardContainer";

const HomePage: FC = () => {
  return (
    <div>
      <EducationalProgramContainer />

      <FeedbackContainer />

      <TeamCardContainer />
    </div>
  );
};

export default HomePage;
