import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Rules = () => {
  const { t } = useTranslation();

  return (
    <Box marginBottom="150px">
      <Box>
        <Typography color="rgb(110, 193, 228)" variant="h3">
          {t("rules")}
        </Typography>
      </Box>
      <Box marginTop="50px">{t("rulesDescription")}</Box>
    </Box>
  );
};

export default Rules;
