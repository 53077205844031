import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import Img from "../image/Image";
import useDeviceSizes from "../../hooks/DeviceSize";

interface SlideProps {
  text: string;
  title?: string;
  img: string;
}

const Slide: FC<SlideProps> = ({ text, img, title }) => {
  const device = useDeviceSizes();

  return (
    <Box display={"flex"} gap="10px">
      <Box width={device === "desktop" ? "40%" : "100%"}>
        {
          <>
            {title ? (
              <>
                <Typography variant="h6" color="rgb(110, 193, 228)">
                  {title}
                </Typography>
                <br />
              </>
            ) : null}
            {text ? <Typography>{text}</Typography> : null}
          </>
        }
      </Box>
      {device === "desktop" && (
        <Box width={"60%"} height={"100%"}>
          <Img width={"100%"} height={"100%"} alt="img" src={img} />
        </Box>
      )}
    </Box>
  );
};

export default Slide;
