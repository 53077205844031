import React, { FC, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteSpeaker from "./DeleteSpeaker";
import SpeakerImage from "./SpeakerImage";

export interface SpeakerData {
  id: number | string;
  name: string;
  text: string;
  imageName: string;
}

interface SpeakerProps {
  data: SpeakerData[];
}

const Speaker: FC<SpeakerProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.id}>
                <SpeakerImage imageName={row.imageName} name={row.name} />
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.text}</TableCell>
                <TableCell>
                  <DeleteSpeaker id={row.id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(Speaker);
