import { Container, Grid, Typography } from "@mui/material";
import ImageUpload from "./ImageUpload";
import { useFormikContext } from "formik";

function ImageUploadContainer() {
  const formik = useFormikContext();

  const handleFilesUploaded = (files: File | null) => {
    formik.setFieldValue("image", files);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Upload Image</Typography>
        </Grid>
        <Grid item xs={12}>
          <ImageUpload onFileUploaded={handleFilesUploaded} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ImageUploadContainer;
