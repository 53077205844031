import { useState, FC, memo } from "react";
import styles from "./HeaderScroll.module.scss";
import classNames from "classnames";
import HeaderScrollButton from "../header-scroll-button/HeaderScrollButton";
import NavLink from "../nav-link/NavLink";

interface Option {
  scrollTop: number;
  label: string;
  pageId: number;
}

interface HeaderScrollProps {
  options: Option[];
  title: string;
  link: string;
  pageId: number;
}

const HeaderScroll: FC<HeaderScrollProps> = ({
  options,
  pageId,
  title,
  link,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={classNames(styles[`dropdown`], {
        [styles.dropdown_opened]: isOpen,
      })}
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <NavLink to={link} pageId={pageId}>
        {title}
      </NavLink>

      {isOpen && (
        <ul style={{ minWidth: "142px" }}>
          {options.map((option) => (
            <HeaderScrollButton
              pageId={option.pageId}
              link={link}
              key={option.label}
              scrollTop={option.scrollTop}
              value={option.label}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(HeaderScroll);
