import React from "react";
import StatsContainer from "../components/stats/StatsContainer";
import { useGetStatsQuery } from "../api/stats";

const Stats = () => {
  const {
    data = {
      academy: 0,
      courses: 0,
      id: 0,
      students: 0,
    },
  } = useGetStatsQuery({});

  return <StatsContainer data={data} />;
};

export default Stats;
