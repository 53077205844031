import { Box, Typography } from "@mui/material";
import React from "react";
import SlickSlider from "../slider/Slider";
import { NextArrowIcon, PrevArrowIcon } from "../../icons";
import styles from "./Slider.module.scss";
import { useTranslation } from "react-i18next";
import useDeviceSizes from "../../hooks/DeviceSize";
import { useGetStatsQuery } from "../../api";

const EducationalProgramContainer = () => {
  const { t } = useTranslation();
  const device = useDeviceSizes();
  const { data } = useGetStatsQuery({});

  return (
    <Box component="section">
      <Typography
        variant={device === "desktop" ? "h2" : "h4"}
        color="rgb(110, 193, 228)"
      >
        {t("educationalProgramOfTheAcademy")}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          marginY: "20px",
        }}
      >
        {t("educationalProgramOfAcademy")}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "10px",
        }}
        color="rgb(110, 193, 228)"
      >
        {t("educationalProgramOfTheAcademyDescription")}
      </Typography>

      <Box>
        <Typography variant="h6">
          {t("educationalProgramOfTheAcademyEducation")}
        </Typography>
      </Box>
      <Box marginY="100px">
        <SlickSlider
          settings={{
            dots: device === "desktop" ? true : false,
            dotsClass: styles.dots,
            pauseOnDotsHover: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: true,
            swipe: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  nextArrow: (
                    <Box>
                      <NextArrowIcon />
                    </Box>
                  ),
                  prevArrow: (
                    <Box>
                      <PrevArrowIcon />
                    </Box>
                  ),
                },
              },
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                },
              },
            ],
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={
          device === "mobile" || device === "tablet" ? "column" : "row"
        }
        gap={device === "mobile" || "tablet" ? "20px" : "0px"}
      >
        <Box component="span" width="100%" display="flex">
          <Typography
            color="rgb(110, 193, 228)"
            variant={device === "desktop" ? "h3" : "h5"}
          >
            {data?.academy} {t("academy")}
          </Typography>
        </Box>
        <Box component="span" width="100%" display="flex">
          <Typography
            color="rgb(110, 193, 228)"
            variant={device === "desktop" ? "h3" : "h5"}
          >
            {data?.students} {t("participant")}
          </Typography>
        </Box>
        <Box component="span" width="100%">
          <Typography
            color="rgb(110, 193, 228)"
            variant={device === "desktop" ? "h3" : "h5"}
          >
            {data?.courses} {t("communityProgram")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EducationalProgramContainer;
