import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SpeakerRequestModel, SpeakerResponseModel } from "../models";

const baseUrl = "https://nextgen.am/api/v1";

export const speakerApi = createApi({
  reducerPath: "speaker",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Teacher"],
  endpoints: (builder) => ({
    getSpeakers: builder.query<SpeakerResponseModel[], { lang: string }>({
      query: ({ lang }) => ({
        url: `/teacher/${lang}`,
        method: "GET",
      }),
      providesTags: ["Teacher"],
    }),

    addSpeaker: builder.mutation<SpeakerResponseModel, SpeakerRequestModel>({
      query: (newSpeaker) => ({
        url: "/teacher",
        method: "POST",
        body: newSpeaker,
      }),
      invalidatesTags: ["Teacher"],
    }),
    deleteSpeaker: builder.mutation<
      SpeakerResponseModel,
      { id: string | number }
    >({
      query: ({ id }) => ({
        url: `/teacher/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Teacher"],
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const {
  useGetSpeakersQuery,
  useAddSpeakerMutation,
  useDeleteSpeakerMutation,
} = speakerApi;
