import React, { FC } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide from "./Slide";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import data from "./educationalProgramData.json";

interface SlickSliderProps {
  settings: Settings;
}

const SlickSlider: FC<SlickSliderProps> = ({ settings }) => {
  const { t } = useTranslation();
  return (
    <Box marginBottom={"50px"}>
      <Slider {...settings}>
        {data.map((slide) => (
          <Slide
            key={slide.title}
            title={t(slide.title)}
            img={slide.img}
            text={t(slide.description)}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default SlickSlider;
