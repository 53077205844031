import { Box } from "@mui/material";
import AboutAcademyListContainer from "../components/about-academy-list/AboutAcademyListContainer";
import StudyFormatContainer from "../components/study-format/StudyFormatContainer";
import ProfileCardContainer from "../components/profile-card/ProfileCardContainer";
import Rules from "../components/rules/Rules";

const AboutAcademy = () => {
  return (
    <Box>
      <AboutAcademyListContainer />
      <StudyFormatContainer />
      <Box marginY="100px">
        <ProfileCardContainer />
      </Box>
      <Box>
        <Rules />
      </Box>
    </Box>
  );
};
export default AboutAcademy;
