import { createSlice } from "@reduxjs/toolkit";

interface LoginState {
  login: boolean;
  admin: boolean;
}

const initialState: LoginState = {
  login: false,
  admin: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logIn(state) {
      state.login = true;
      sessionStorage.setItem("login", `${state.login}`);
    },
    logOut(state) {
      state.login = false;
      sessionStorage.setItem("login", `false`);
    },
    adminUser(state) {
      state.admin = true;
    },
    notAdminUser(state) {
      state.admin = false;
    },
  },
});

export const { logIn, logOut, adminUser, notAdminUser } = loginSlice.actions;
export default loginSlice.reducer;
