import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetStaffRequestModel,
  StaffRequestModel,
  StaffResponseModel,
} from "../models";

const baseUrl =
  "https://nextgen.am/api/v1";

export const staffApi = createApi({
  reducerPath: "staff",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Staff"],
  endpoints: (builder) => ({
    getStaff: builder.query<StaffResponseModel[], GetStaffRequestModel>({
      query: ({ lang }) => ({
        url: `/staff/${lang}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }),
      providesTags: ["Staff"],
    }),

    addStaff: builder.mutation<StaffResponseModel, StaffRequestModel>({
      query: (newStaff) => ({
        url: "/staff",
        method: "POST",
        body: newStaff,
      }),
      invalidatesTags: ["Staff"],
    }),

    deleteStaff: builder.mutation<StaffResponseModel, { id: string | number }>({
      query: ({ id }) => ({
        url: `/staff/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Staff"],
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const { useGetStaffQuery, useAddStaffMutation, useDeleteStaffMutation } =
  staffApi;
