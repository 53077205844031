import React, { FC, memo } from "react";
import { Box, Typography } from "@mui/material";
import Stats from "./Stats";
import Sidebar from "../sidebar/Sidebar";
import { StatsResponseModel } from "../../models/response/StatsResponseModel";

interface StatsContainerProps {
  data: StatsResponseModel;
}

const StatsContainer: FC<StatsContainerProps> = ({ data }) => {
  return (
    <Box>
      <Sidebar>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="rgb(110, 193, 228)">
            Statss
          </Typography>
          <Box component="hr" width="100%" marginX="10px" />
        </Box>
        <Box marginY="100px" height="100%" width="100%" padding="10px">
          <Box
            border="1px solid rgb(110, 193, 228)"
            borderRadius="8px"
            display="flex"
            justifyContent="space-between"
            marginBottom="30px"
          ></Box>
          <Stats data={data} />
        </Box>
      </Sidebar>
    </Box>
  );
};

export default memo(StatsContainer);
