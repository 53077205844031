import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";

interface MultiImageUploadProps {
  onFilesUploaded: (files: File[]) => void;
}

const MultiImageUpload: React.FC<MultiImageUploadProps> = ({
  onFilesUploaded,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const invalidFiles = acceptedFiles.filter(
        (file) => !SUPPORTED_FORMATS.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        return;
      }
      const updatedFiles = [...uploadedFiles, ...acceptedFiles];
      setUploadedFiles(updatedFiles);
      onFilesUploaded(updatedFiles);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadedFiles, onFilesUploaded]
  );

  const onDeleteFile = (fileIndex: number) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(fileIndex, 1);
    setUploadedFiles(updatedFiles);
    onFilesUploaded(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Paper elevation={3} style={{ padding: "16px", textAlign: "center" }}>
      <div {...getRootProps()} style={dropzoneStyles(isDragActive)}>
        <input {...getInputProps()} />
        <CloudUploadIcon style={{ fontSize: 80, color: "#2196F3" }} />
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          {isDragActive
            ? "Drop files here"
            : "Drag and drop files here, or click to select files"}
        </Typography>
      </div>
      <List>
        {uploadedFiles.map((file, index) => (
          <ListItem key={index}>
            <ListItemText primary={file.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => onDeleteFile(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

const dropzoneStyles = (isDragActive: boolean): React.CSSProperties => ({
  border: `2px dashed ${isDragActive ? "#2196F3" : "#888888"}`,
  borderRadius: "4px",
  padding: "32px",
  cursor: "pointer",
});

export default MultiImageUpload;
