import React from "react";
import StudyFormat from "./StudyFormat";
import { Box, Typography } from "@mui/material";
import useDeviceSizes from "../../hooks/DeviceSize";
import { useTranslation } from "react-i18next";

const StudyFormatContainer = () => {
  const device = useDeviceSizes();

  const { t } = useTranslation();

  return (
    <Box marginTop="100px">
      <Typography
        color="rgb(110, 193, 228)"
        variant={device === "mobile" || "tablet" ? "h4" : "h3"}
        marginY="25px"
      >
        {t("learningFormat")}
      </Typography>
      <StudyFormat />
    </Box>
  );
};

export default StudyFormatContainer;
