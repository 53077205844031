// uploadApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL
const baseUrl =
  "https://nextgen.am/api/v1";

// Create an instance of createApi
export const uploadApi = createApi({
  reducerPath: "upload",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    upload: builder.mutation<void, any>({
      query: (files) => {
        return {
          url: "/file/upload",
          method: "POST",
          body: { files },
          headers: {
            "Content-Type": "multipart/form-data",
            "Content-Length": files.length,
          },
        };
      },
    }),

    getImage: builder.query<any, any>({
      query: (name) => ({
        url: `/file/download/${name}`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }),
    }),
  }),
});

// Export the hooks and endpoints for use in components
export const { useUploadMutation, useGetImageQuery } = uploadApi;
