import React, { useState, useEffect } from "react";
import { useGetSpeakersQuery } from "../../api";
import ProfileCard from "./ProfileCard";
import { Box, Collapse, Typography } from "@mui/material";
import useDeviceSizes from "../../hooks/DeviceSize";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

const ProfileCardContainer = () => {
  const { t, i18n } = useTranslation();
  const { data = [] } = useGetSpeakersQuery({ lang: i18n.language });
  const device = useDeviceSizes();
  const selector = useSelector((state: RootState) => state.collapse.opened);

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((e) => !e);
  };

  useEffect(() => {
    if (selector) {
      setExpanded(false);
    }
  }, [selector]);

  return (
    <Box>
      <Typography
        color="rgb(110, 193, 228)"
        variant={device === "mobile" || "tablet" ? "h4" : "h3"}
        marginY="25px"
      >
        Բանախոսներ
      </Typography>
      <Collapse
        in={expanded}
        timeout={500}
        style={{
          minHeight: "600px",
          visibility: "visible",
        }}
      >
        <Box
          display="grid"
          //   minHeight={"400px"}
          gridTemplateColumns={
            device === "mobile" ? "repeat(1, 1fr)" : "repeat(3, 1fr)"
          }
          gap={"50px"}
          marginBottom="25px"
        >
          {data.map((profile) => (
            <ProfileCard
              key={profile.id}
              text={profile.text}
              img={profile.imageName}
              name={profile.name}
            />
          ))}
        </Box>
      </Collapse>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          onClick={toggleExpanded}
          variant="h6"
          sx={{ cursor: "pointer" }}
          color="rgb(110, 193, 228)"
        >
          {data.length > 3 && (expanded ? t("showLess") : t("showMore"))}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileCardContainer;
