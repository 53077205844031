import { Avatar, Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";

interface ProfileImageProps {
  name: string;
  imageName: string;
}

const ProfileImage: FC<ProfileImageProps> = ({ imageName, name }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const imageUrl = `https://nextgen.am/api/v1/file/download/${imageName}`;

    fetch(imageUrl, {
      headers: {
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob(); // Get the response as a binary blob
      })
      .then((blob) => {
        // Create a URL for the blob data
        const objectURL = URL.createObjectURL(blob);
        setImageSrc(objectURL);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  }, [imageName]); // Empty

  return (
    <Box width="100%" height="100%">
      <Avatar
        sx={{ width: "100%", height: "100%" }}
        alt={name}
        src={imageSrc}
      />
    </Box>
  );
};

export default ProfileImage;
