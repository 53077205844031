import { ClickAwayListener, ListItemButton } from "@mui/material";
import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavbarScrollButton.module.scss";
import classNames from "classnames";
import ScrollHandler from "../../helpers/scrollHandler";
import { useDispatch } from "react-redux";
import { setOpened } from "../../store/collapseSlice";

interface NavbarScrollButtonProps {
  value: string;
  label: string;
  scrollTop: number;
}

const NavbarScrollButton: FC<NavbarScrollButtonProps> = ({
  scrollTop,
  label,
  value,
}) => {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <ListItemButton
        sx={{ pl: 4 }}
        key={value}
        className={classNames(styles["navbar-scroll--button"], {
          [styles["navbar-scroll--button__active"]]: active,
        })}
      >
        <NavLink
          //@ts-expect-error
          isMobile
          to={value}
          onClick={() => {
            setActive(true);
            ScrollHandler({ scrollTop });
            dispatch(setOpened(true));
            setTimeout(() => {
              dispatch(setOpened(false));
            }, 100);
          }}
        >
          {label}
        </NavLink>
      </ListItemButton>
    </ClickAwayListener>
  );
};

export default NavbarScrollButton;
