import { useCallback, useState } from "react";
import { useGetSpeakersQuery } from "../api";
import SpeakerContainer from "../components/speakers/SpeakerContainer";

const Speakers = () => {
  const [language, setLanguage] = useState("en");

  const changeLanguage = useCallback((e: string) => {
    setLanguage(e);
  }, []);

  const { data = [] } = useGetSpeakersQuery({ lang: language });

  return (
    <SpeakerContainer
      lang={language}
      changeLanguage={changeLanguage}
      data={data}
    />
  );
};

export default Speakers;
