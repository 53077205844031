import { List } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import NavLink from "../nav-link/NavLink";
import NavbarSelect from "../navbar-select/NavbarSelect";

const Navbar = () => {
  const { t } = useTranslation();
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <NavbarSelect
        generalLabel={t("aboutAcademy")}
        labels={[
          {
            value: "/about",
            label: t("expectedEndResult"),
            scrollTop: 0,
          },
          {
            value: "/about",
            label: t("learningFormat"),
            scrollTop: 1500,
          },
          {
            value: "/about",
            label: t("speakers"),
            scrollTop: 1880,
          },
          {
            value: "/about",
            label: t("rules"),
            scrollTop: 2700,
          },
        ]}
      />
      <NavLink isMobile isShowActive pageId={1508} to="/community">
        {t("NextGenCommunity")}
      </NavLink>
      <NavLink isMobile isShowActive pageId={1750} to="/talks">
        {t("NextGenTalks")}
      </NavLink>
    </List>
  );
};

export default memo(Navbar);
