import { FC, memo } from "react";
import { NavLink as Link } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/pageSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import classNames from "classnames";
import styles from "./NavLink.module.scss";

export interface LinkProp {
  children: string;
  to: string;
  isMobile?: boolean;
  className?: string;
  pageId?: number;
  isShowActive?: boolean;
}

const NavLink: FC<LinkProp> = ({
  className,
  isMobile = false,
  children,
  pageId,
  to,
  isShowActive = false,
}) => {
  const dispatch = useDispatch();
  const page = useSelector((state: RootState) => state.page.page);

  return (
    <Box
      sx={
        isMobile
          ? {
              width: "100%",
            }
          : {}
      }
    >
      <Link
        to={to}
        className={classNames(
          className,
          {
            [styles.navLink_active]: page === pageId,
            [styles.navLink_mobile]: isMobile,
            [styles.navLink_isSowActive]: isShowActive === true,
          },
          styles.navLink
        )}
        onClick={(e) => dispatch(setPage(pageId))}
      >
        {children}
      </Link>
    </Box>
  );
};
export default memo(NavLink);
