import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { StatsResponseModel } from "../models/response/StatsResponseModel";
import { StatsRequestMode } from "../models/request/StatsRequestMode";

const baseUrl =
  "https://nextgen.am/api/v1";

export const statsApi = createApi({
  reducerPath: "stats",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Stats"],
  endpoints: (builder) => ({
    getStats: builder.query<StatsResponseModel, any>({
      query: () => ({
        url: `/stats`,
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }),
      providesTags: ["Stats"],
    }),

    changeStats: builder.mutation<StatsResponseModel, StatsRequestMode>({
      query: (data) => ({
        url: `/stats`,
        method: "PUT",
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
        body: data,
      }),
      invalidatesTags: ["Stats"],
    }),
  }),
});

export const { useGetStatsQuery, useChangeStatsMutation } = statsApi;
