import React, { FC } from "react";
import { Box } from "@mui/material";
import FullSizeImage from "../full-size-image/FullSizeImage";

interface TalksImageListProps {
  images: string[];
}

const TalksImageList: FC<TalksImageListProps> = ({ images }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={"10px"}
    >
      {images.map((image) => (
        <Box
          width={"33%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <FullSizeImage imageName={image} />
        </Box>
      ))}
    </Box>
  );
};

export default TalksImageList;
