import { useState, useEffect } from "react";

type DeviceSizes = "mobile" | "tablet" | "desktop";

const useDeviceSizes = (): DeviceSizes => {
  const [deviceSizes, setDeviceSizes] = useState<DeviceSizes>("desktop");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth < 420;
      const isTablet = screenWidth >= 420 && screenWidth < 1024;

      setDeviceSizes(isMobile ? "mobile" : isTablet ? "tablet" : "desktop");
    };

    // Initial setup
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return deviceSizes;
};

export default useDeviceSizes;
