import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  memo,
  useEffect,
  useState,
} from "react";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import {
  Box,
  Typography,
  TablePagination,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import Speaker, { SpeakerData } from "./Speaker";
import AddSpeaker from "./AddSpeaker";
import Sidebar from "../sidebar/Sidebar";
import { SpeakerResponseModel } from "../../models";

interface SpeakerContainerProps {
  data: SpeakerResponseModel[] | [];
  lang: string;
  changeLanguage: (e: string) => void;
}

const SpeakerContainer: FC<SpeakerContainerProps> = ({
  data,
  lang,
  changeLanguage,
}) => {
  const rowsPerPageOptions = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchTerms, setSearchTerms] = useState<{
    id: string;
    name: string;
    text: string;
  }>({
    id: "",
    name: "",
    text: "",
  });
  const [filteredData, setFilteredData] = useState<SpeakerData[]>(data || []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange =
    (field: keyof SpeakerData) => (event: ChangeEvent<HTMLInputElement>) => {
      const updatedSearchTerms = {
        ...searchTerms,
        [field]: event.target.value,
      };
      setSearchTerms(updatedSearchTerms);
      filterData(updatedSearchTerms);
    };

  const filterData = (searchTerms: {
    id: string;
    name: string;
    text: string;
  }) => {
    const filtered =
      data &&
      data.filter((row) => {
        if (!row) {
          return false;
        }

        const idMatch =
          row.id &&
          row.id
            .toString()
            .toLowerCase()
            .includes(searchTerms.id.toLowerCase());
        const nameMatch =
          row.name &&
          row.name.toLowerCase().includes(searchTerms.name.toLowerCase());
        const engNameMatch =
          row.engName &&
          row.engName.toLowerCase().includes(searchTerms.name.toLowerCase());
        const textMatch =
          row.text &&
          row.text.toLowerCase().includes(searchTerms.text.toLowerCase());
        const engTextMatch =
          row.engText &&
          row.engText.toLowerCase().includes(searchTerms.text.toLowerCase());

        return (
          idMatch && (nameMatch || engNameMatch) && (textMatch || engTextMatch)
        );
      });
    filtered && setFilteredData(filtered);
    setPage(0);
  };
  const slicedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box>
      <Sidebar>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="rgb(110, 193, 228)">
            Speakers
          </Typography>
          <Box component="hr" width="100%" marginX="10px" />
          <AddSpeaker />
        </Box>
        <Box marginY="100px" height="100%" width="100%" padding="10px">
          <Box
            border="1px solid rgb(110, 193, 228)"
            borderRadius="8px"
            display="flex"
            justifyContent="space-between"
            marginBottom="30px"
          >
            <Box
              borderRadius="8px"
              padding="25px"
              display="grid"
              gridTemplateColumns="auto auto auto"
              gap="25px"
              width="100%"
            >
              <TextField
                label="ID"
                value={searchTerms.id}
                onChange={handleSearchChange("id")}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Name"
                value={searchTerms.name}
                onChange={handleSearchChange("name")}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Description"
                value={searchTerms.text}
                onChange={handleSearchChange("text")}
                variant="outlined"
                size="small"
              />
            </Box>
            <Box marginTop="20px" marginRight="15px">
              <IconButton
                onClick={() => {
                  setSearchTerms({
                    id: "",
                    text: "",
                    name: "",
                  });
                  setFilteredData(data);
                }}
              >
                <ClearAllIcon />
              </IconButton>
            </Box>
          </Box>

          <Box>
            <Button
              color={lang === "am" ? "primary" : "inherit"}
              onClick={() => {
                changeLanguage("am");
              }}
            >
              Hay
            </Button>
            <Button
              color={lang === "en" ? "primary" : "inherit"}
              onClick={() => {
                changeLanguage("en");
              }}
            >
              ENG
            </Button>
          </Box>

          <Speaker data={slicedData} />

          {data.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <>No Data</>
          )}
        </Box>
      </Sidebar>
    </Box>
  );
};

export default memo(SpeakerContainer);
