import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { FastField, FieldMetaProps, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useAddStaffMutation } from "../../api";
import ImageUploadContainer from "../image-upload/ImageUploadContainer";

interface FastFiledProps {
  field: FieldMetaProps<string>;
  meta: FieldMetaProps<string>;
  form: any;
}

interface FormValues {
  image: any;
  name: string;
  text: string;
  engName: string;
  engText: string;
}

const AddStaff = () => {
  const [AddStaff] = useAddStaffMutation();

  const [language, setLanguage] = useState<string>("am");

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: FormValues) => {
    const formData = new FormData();
    formData.append("files", values.image); // Add files to upload

    // Make a POST request to upload the files
    fetch(
      "https://nextgen.am/api/v1/file/upload",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        AddStaff({
          name: values.name,
          engName: values.engName,
          engText: values.engText,
          text: values.text,
          imageName: data[0],
        });
        handleClose();
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  const initialValues = {
    image: null,
    name: "",
    text: "",
    engName: "",
    engText: "",
  };
  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Add Staff Member
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "500px",
          },
        }}
        style={{ minWidth: "500px" }}
      >
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>Add Staff Member</DialogTitle>
          <Box display="flex" marginRight="24px">
            <Button
              onClick={() => {
                setLanguage("am");
              }}
              variant={language === "am" ? "contained" : "outlined"}
              sx={{ height: "40px", borderRadius: "0px", marginTop: "16px" }}
            >
              Հայերեն
            </Button>
            <Button
              variant={language === "en" ? "contained" : "outlined"}
              onClick={() => {
                setLanguage("en");
              }}
              sx={{ height: "40px", borderRadius: "0px", marginTop: "16px" }}
            >
              English
            </Button>
          </Box>
        </Box>

        <DialogContent>
          <Box width="100%" height="100%" padding="8px">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("A name is required"),
                text: Yup.string().required("A description is required"),
                engName: Yup.string().required("A name is required"),
                engText: Yup.string().required("A description is required"),
                image: Yup.mixed().required("A file is required"),
              })}
              onSubmit={handleSubmit}
            >
              <Form>
                <Box
                  display={language === "am" ? "flex" : "none"}
                  flexDirection="column"
                  gap={"10px"}
                >
                  <FastField name="name">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Name"
                          name="name"
                          id="name"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("name", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                  <FastField name="text">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Description"
                          name="text"
                          id="text"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("text", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>

                  <FastField name="image">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <>
                          <ImageUploadContainer />
                          <Typography color="red">{meta.error}</Typography>
                        </>
                      );
                    }}
                  </FastField>
                </Box>
                <Box
                  display={language === "en" ? "flex" : "none"}
                  flexDirection="column"
                  gap={"10px"}
                >
                  <FastField name="engName">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Name"
                          name="engName"
                          id="engName"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("engName", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                  <FastField name="engText">
                    {({ field, form, meta }: FastFiledProps) => {
                      return (
                        <TextField
                          label="Description"
                          name="engText"
                          id="engText"
                          variant="outlined"
                          size="small"
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue("engText", event.target.value);
                          }}
                          error={meta.error ? true : false}
                          helperText={meta.error}
                        />
                      );
                    }}
                  </FastField>
                </Box>

                <DialogActions>
                  <Button type="submit" color="primary">
                    Add
                  </Button>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Form>
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddStaff;
