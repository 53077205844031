import React, { memo, useEffect, useState } from "react";
import Feedback from "./Feedback";
import { Box, Collapse, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDeviceSizes from "../../hooks/DeviceSize";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useGetFeedbacksQuery } from "../../api";

function MyCollapse() {
  const { i18n, t } = useTranslation();

  const { data } = useGetFeedbacksQuery({ language: i18n.language });

  const selector = useSelector((state: RootState) => state.collapse.opened);

  const device = useDeviceSizes();

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((e) => !e);
  };

  useEffect(() => {
    if (selector) {
      setExpanded(false);
    }
  }, [selector]);

  return (
    <Box
      component="section"
      sx={{
        marginY: "50px",
      }}
    >
      <Typography
        variant={device === "mobile" || "tablet" ? "h4" : "h2"}
        color={"rgb(110, 193, 228)"}
      >
        {t("success")}
      </Typography>
      <Collapse
        in={expanded}
        timeout={500}
        style={{
          minHeight: "690px",
          visibility: "visible",
        }}
      >
        <Box
          display="grid"
          minHeight={"400px"}
          gridTemplateColumns={
            device === "mobile" ? "repeat(1, 1fr)" : "repeat(3, 1fr)"
          }
          gap={"50px"}
        >
          {data?.map((data, index) => (
            <Feedback name={data.name} desciption={data.text} key={index} />
          ))}
        </Box>
      </Collapse>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          onClick={toggleExpanded}
          variant="h6"
          sx={{ cursor: "pointer" }}
          color="rgb(110, 193, 228)"
        >
          {data &&
            data.length > 3 &&
            (expanded ? t("showLess") : t("showMore"))}
        </Typography>
      </Box>
    </Box>
  );
}

export default memo(MyCollapse);
