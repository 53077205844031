import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteTalks from "./DeleteTalks";
import TalksImage from "./TalksImage";

export interface TalksData {
  id: number | string;
  text: string;
  engText: string;
  imageNames: string[];
}

interface TalksProps {
  data: TalksData[];
}

const Talks: FC<TalksProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "none",
                  }}
                >
                  {row.imageNames?.map((imageName) => (
                    <TalksImage imageName={imageName} name={row.text} />
                  ))}
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.text}</TableCell>
                <TableCell>
                  <DeleteTalks id={row.id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Talks;
