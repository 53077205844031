import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteCourse from "./DeleteCourse";
import CourseImage from "./CourseImage";

export interface CourseData {
  id: number | string;
  name: string;
  text: string;
  imageNames: string[];
}

interface CourseProps {
  data: CourseData[];
}

const Course: FC<CourseProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "none",
                  }}
                >
                  {row.imageNames?.map((imageName) => (
                    <CourseImage imageName={imageName} name={row.name} />
                  ))}
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.text}</TableCell>
                <TableCell>
                  <DeleteCourse id={row.id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Course;
