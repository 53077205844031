import { FC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import News from "./pages/News";
import Contact from "./pages/Contact";
import HeaderContainer from "./components/header/HeaderContainer";
import { Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import NextGenTalks from "./pages/NextGenTalks";
import NextGenCommunity from "./pages/NextGenCommunity";
import AboutAcademy from "./pages/AboutAcademy";
import Speakers from "./pages/Speakers";
import Login from "./components/login/Login";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import Feedbacks from "./pages/Feedbacks";
import Staff from "./pages/Staff";
import Course from "./pages/Course";
import Talks from "./pages/Talks";
import Footer from "./components/footer/Footer";
import Stats from "./pages/Stats";

const App: FC = () => {
  const matches = useMediaQuery("(max-width:900px)");

  const login = useSelector((state: RootState) => state.login.login);

  const localLogin = sessionStorage.getItem("login");

  const isLogin = login === true || localLogin === "true" ? true : false;

  return (
    <>
      <Router>
        {isLogin ? (
          <Container
            sx={{
              margin: "auto",
              marginTop:
                matches && !isLogin ? "120px" : !isLogin ? "180px" : "50px",
            }}
          >
            <Routes>
              <Route path="/admin/speakers" element={<Speakers />} />
              <Route path="/admin/feedbacks" element={<Feedbacks />} />
              <Route path="/admin/staff" element={<Staff />} />
              <Route path="/admin/courses" element={<Course />} />
              <Route path="/admin/talks" element={<Talks />} />
              <Route path="/admin/stats" element={<Stats />} />
              <Route path="/admin" element={<Login />} />
            </Routes>
          </Container>
        ) : (
          <>
            <HeaderContainer />
            <Container
              sx={{
                margin: "auto",
                marginTop:
                  matches && !isLogin ? "120px" : !isLogin ? "180px" : "50px",
              }}
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/admin" element={<Login />} />
                <Route path="/about" element={<AboutAcademy />} />
                <Route path="/community" element={<NextGenCommunity />} />
                <Route path="/talks" element={<NextGenTalks />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/news" element={<News />} />
              </Routes>
            </Container>
            <Footer />
          </>
        )}
      </Router>
    </>
  );
};

export default App;
